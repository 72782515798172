import React, { useState } from "react";

import { Button, ButtonGroup } from "@material-ui/core";
import styled from "styled-components";

import EditCouponModal from "~/components/Subscriptions/EditCouponModal";

const Content = styled.div`
  background-color: whitesmoke;
  border-radius: 1rem;
  padding: 1rem;
  display: inline-block;
  padding: 1em 2em 1em 1em;
  text-align: left;

  p {
    margin: 0.5rem;
    font-weight: 100;
  }

  h3 {
    margin-right: 2rem;
  }
`;

type Coupon = {
  referral_code: string;
  discount: number;
  global_discount: boolean;
  specific_items: any[];
};

type CouponDetailProps = {
  coupon: Coupon;
  refetch: () => void;
  subscriptionId: string;
};

const CouponDetail = (props: CouponDetailProps) => {
  const [isEditCouponModalOpen, setIsEditCouponModalOpen] = useState<boolean>(false);

  const handleCloseEditCouponModal = (reload?: boolean): void => {
    setIsEditCouponModalOpen(false);
    if (reload) {
      props.refetch();
    }
  };

  return (
    <Content>
      <EditCouponModal
        isOpen={isEditCouponModalOpen}
        closeModal={handleCloseEditCouponModal}
        subscriptionId={props.subscriptionId}
        coupon={props.coupon}
      />
      <div className="flex flex-col gap-y-2">
        <div>
          <span className="font-bold">Cupón: </span>
          {props.coupon.referral_code}
        </div>
        <div>
          <span className="font-bold">Descuento: </span>
          {props.coupon.discount * 100}%
        </div>
        <div>
          <span className="font-bold">¿Aplica para todos los servicios? </span>
          {props.coupon.global_discount ? "Sí" : "No"}
        </div>
        {!props.coupon.global_discount && (
          <div className="flex flex-col gap-y-2">
            <span className="font-bold">Servicios para los que aplica: </span>
            {props.coupon.specific_items?.map((item) => (
              <span>{item.name}</span>
            ))}
          </div>
        )}
        <ButtonGroup
          variant="outlined"
          color="primary"
        >
          <Button onClick={() => setIsEditCouponModalOpen(true)}>Editar cupón</Button>
        </ButtonGroup>
      </div>
    </Content>
  );
};

export default CouponDetail;
