import React, { useState } from "react";

import { Button, ButtonGroup } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";

import ModalContainer from "~/components/Containers/ModalContainer";
import LoadingError from "~/components/Loaders/LoadingError";
import subscriptionsAPI from "~/utils/api/v2/subscriptions";

type DeleteServiceModalProps = {
  isOpen: boolean;
  closeModal: (reload?: boolean) => void;
  item: any;
  subscriptionId: string;
};

const DeleteServiceModal = ({ isOpen, closeModal, item, subscriptionId }: DeleteServiceModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const handleDelete = async () => {
    setLoading(true);
    try {
      const req = await subscriptionsAPI.deleteSubscriptionItem(subscriptionId, item.id);
      closeModal(true);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        closeModal();
        document.getElementById("navbar").style.zIndex = "1999";
      }}
      style={{ height: "100%" }}
    >
      <ModalContainer>
        <LoadingError
          loading={loading}
          error={error}
        />
        {!loading && (
          <>
            <div className="text-xl mb-2">¿Estás segur@ de querer eliminar este servicio?</div>
            <div className="flex flex-row justify-between">
              <ButtonGroup
                variant="outlined"
                color="secondary"
              >
                <Button onClick={handleDelete}>Eliminar</Button>
              </ButtonGroup>
              <ButtonGroup
                variant="outlined"
                color="primary"
              >
                <Button onClick={() => closeModal()}>Cancelar</Button>
              </ButtonGroup>
            </div>
          </>
        )}
      </ModalContainer>
    </Modal>
  );
};

export default DeleteServiceModal;
