import React from "react";

import clsx from "clsx";

import LoadingSpinnerIcon from "~/components/icons/LoadingSpinnerIcon";

type ActionButtonProps = {
  text: string;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  loading?: boolean;
};

const ActionButton = ({ text, type = "button", disabled, className, onClick, loading = false }: ActionButtonProps) => {
  return (
    <button
      type={type}
      className={clsx(
        "text-white",
        "rounded-md shadow",
        "bg-examedi-blue-strong hover:bg-examedi-blue-strong-75",
        "disabled:bg-examedi-gray-3 disabled:cursor-not-allowed",
        className,
      )}
      onClick={() => onClick?.()}
      disabled={disabled}
    >
      {!loading && text}
      {loading && <LoadingSpinnerIcon />}
    </button>
  );
};

export default ActionButton;
