import React, { useState } from "react";

import { TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";

import ActionButton from "~/components/Common/ActionButton";
import ModalContainer from "~/components/Containers/ModalContainer";
import subscriptionsAPI from "~/utils/api/v2/subscriptions";
import type Subscription from "~/utils/interfaces/Subscriptions";

type PauseSubscriptionModalProps = {
  isOpen: boolean;
  subscription: Subscription;
  closeModal: (reload?: boolean) => void;
};

const PauseSubscriptionModal = ({ isOpen, subscription, closeModal }: PauseSubscriptionModalProps) => {
  const [reason, setReason] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handlePauseSubscription = async () => {
    setLoading(true);
    try {
      await subscriptionsAPI.pauseSubscription(subscription.id, reason).then(() => closeModal(true));
    } catch (e) {
      setError(`Ocurrió un error al pausar la suscripción: ${e}`);
    }
    setLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => closeModal()}
    >
      <ModalContainer>
        <div className="w-96 text-examedi-gray-2 flex flex-col gap-y-5">
          <div className="font-light text-3xl">Pausar/Terminar suscripción</div>
          <div className="text-md font-light leading-5">
            Si desea pausar o terminar la suscripción del usuario, debe dar una razón para esto y presionar &quot;Pausar
            Suscripción&quot;
          </div>
          <div className="flex flex-col gap-y-0">
            <div className="font-light text-sm">Razón:</div>
            <TextField
              onChange={(e) => setReason(e.target.value)}
              placeholder="Suscripción inactiva o no usada"
            />
          </div>
          <div className="w-full flex items-center justify-between gap-x-4">
            <ActionButton
              text="Cancelar suscripción"
              className="px-2.5 py-1.5 w-1/2 bg-examedi-red-strong hover:bg-examedi-red-light"
              onClick={handlePauseSubscription}
              disabled={loading}
            />
            <ActionButton
              text="Abortar acción"
              className="px-2.5 py-1.5 w-1/2 bg-examedi-blue-strong hover:bg-examedi-blue-strong-75"
              onClick={closeModal}
              disabled={loading}
            />
          </div>
          {!!error && <div className="font-light text-examedi-red-strong text-sm">{error}</div>}
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default PauseSubscriptionModal;
